import React from 'react';
import Layout from '../../../../components/layout';
import SEO from '../../../../components/seo';
import { Grid, Box, Typography, Link } from '@material-ui/core';
import ImageContainer from '../../../../components/image-container';
import SectionContainerLayoutWithFilter from '../../../../components/section-container-layout-with-filter';

export default function PlanYouTrip() {
  return (
    <Layout>
      <SEO lang='en' title='Plan Your Trip' />
      <SectionContainerLayoutWithFilter isViewAll title='PLAN YOUR TRIP' baseLink='/press/plan-your-trip' isDivider>
        <Grid container>
          <Box position='relative' clone>
            <Grid item xs={12}>
              <ImageContainer filename='covid-19-protocol-2' altText='plan-your-trip' />
              <Box
                position='absolute'
                left='0'
                style={{
                  top: '50%',
                  transform: 'translateY(-50%)',
                  backgroundColor: 'rgba(0 , 0 , 0 , 0.5)',
                }}>
                <Box p={2}>
                  <Typography variant='h3' style={{ fontWeight: 'bold' }}>
                    COVID-19 Protocols and Vaccine Status Information
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Box>

          <Grid item xs={12}>
            <Box p={2} height='100%'>
              <Typography variant='h6'>
                <br />
                All attendees involved in events taking place at The Dubai Exhibition Centre must ensure that they have
                received both doses of the COVID-19 vaccine.
              </Typography>
              <Typography variant='h6' style={{ fontWeight: 'bold' }}>
                <br /> For all visitors before arriving:
              </Typography>
              <Typography variant='h6'>
                <br />
                Please be ready to present a printed COVID-19 vaccination certificate or a negative PCR result (48
                hours). This is a mandatory precaution for all visitors.
                <br />
                <br />
                For more information on COVID-19 protocols, please visit the following:{' '}
                <Link href='https://www.expo2020dubai.com/en/plan-your-visit/covid-19' target='_blank'>
                  https://
                  <wbr />
                  www.expo2020dubai.com/
                  <wbr />
                  en/
                  <wbr />
                  plan-your-visit/
                  <wbr />
                  covid-19
                </Link>
                <br />
                <br /> <b>
                  For optional PCR testing facility at Dubai Exhibition Centre please get in touch with:
                </b>{' '}
                <br />
                Operator: G42 Biogenix Laboratory.
                <br /> Location: South Concourse
                <br /> Timings: 7 days a week, from 7:00 to 23:00 hours
                <br />
                <br />
                <b>For overseas visitors and their vaccination certificates:</b>
                <br /> Overseas attendees who have received 2 doses of the COVID-19 vaccine in their home country will
                need to register their vaccination before traveling to the UAE through the ICA UAE Smart App or visit:{' '}
                <Link
                  href='https://smartservices.ica.gov.ae/echannels/web/client/guest/index.html#/registerArrivals'
                  target='_blank'>
                  https://
                  <wbr />
                  smartservices.ica.gov.ae/
                  <wbr />
                  echannels/
                  <wbr />
                  web/
                  <wbr />
                  client/
                  <wbr />
                  guest/
                  <wbr />
                  index.html#/
                  <wbr />
                  registerArrivals
                </Link>
                <br /> <br /> Upon approval of the vaccination certificate, they will be able to access it through Al
                Hosn App (Dubai’s COVID-19 record app). For any queries on the process above, the ICA office can be
                reached on +971 249 5555 or through the Contact Call Centre on 600 522222 or by email at{' '}
                <Link href='mailto: contactus@ica.gov.ae'>contactus@ica.gov.ae</Link>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </SectionContainerLayoutWithFilter>
    </Layout>
  );
}
